html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-image: radial-gradient(hsl(0, 0%, 100%), hsl(39, 25%, 95%));
  /* background-color: hsl(39, 25%, 95%); */
  line-height: 1.3;
  color: #111111;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: hsl(27.5, 25%, 35%);
  text-decoration: none;
}
a:visited {
  color: hsl(27.5, 25%, 35%);
}
a:hover {
  text-decoration: underline;
}
